import React from "react";

export function TabNavigation({ activeTab, setActiveTab }) {
  return (
    <div className="fixed bottom-[40px] left-0 right-0 bg-white/95 backdrop-blur-sm border-t border-gray-200 md:hidden z-30">
      <div className="flex justify-around items-center">
        <button onClick={() => setActiveTab("game")} className={`flex-1 py-3 text-center ${activeTab === "game" ? "text-blue-600 border-t-2 border-blue-600" : "text-gray-500"}`}>
          🎲 Game
        </button>
        <button onClick={() => setActiveTab("info")} className={`flex-1 py-3 text-center ${activeTab === "info" ? "text-blue-600 border-t-2 border-blue-600" : "text-gray-500"}`}>
          ℹ️ Info & CA
        </button>
        <button onClick={() => setActiveTab("history")} className={`flex-1 py-3 text-center ${activeTab === "history" ? "text-blue-600 border-t-2 border-blue-600" : "text-gray-500"}`}>
          🏆 History
        </button>
      </div>
    </div>
  );
}
