import React, { useState } from "react";

import { Tooltip } from "../ui/Tooltip";
import { Dice3D } from "../ui/Dice3D";
import { DiceSkeleton } from "../ui/DiceSkeleton";

import { Button } from "../ui/Button";
import { useTypewriter } from "../../hooks/useTypewriter";

export function GameTab({ timeLeft, isRolling, currentRoll, lastResult, balance, publicKey, isLoading, totalBets, overMultiplier, underMultiplier, isBettingLocked, handleBet, currentBets, renderBetList, words }) {
  const { displayText, isTyping } = useTypewriter(words || "", 40);
  const [bettingExpanded, setBettingExpanded] = React.useState(false);

  return (
    <div className="space-y-4">
      {/* Timer & Dice */}
      <div className="flex flex-col gap-36">
        {/* Timer Section */}
        <div className="text-center">
          <div className="glass-card px-3 py-2 rounded-xl">
            <h2 className="text-xl font-semibold">
              <p className={`${timeLeft <= 10 && totalBets > 0 ? "text-red-500 timer-pulse" : "text-gray-500"} font-bold`}>{isRolling ? "ROLLING..." : totalBets === 0 ? "WAITING FOR FIRST BET" : `NEXT ROLL in ${timeLeft}s`}</p>
            </h2>
            {lastResult && (
              <div className={`mt-2 text-lg font-bold ${lastResult === "over" ? "text-blue-500" : lastResult === "under" ? "text-purple-500" : "text-gray-600"}`}>
                <div className="flex items-center justify-center gap-2">
                  <p className="animate-bounce">{lastResult === "over" ? "↑ OVER WINS!" : lastResult === "under" ? "↓ UNDER WINS!" : "= TIE - GOAT WINS!"}</p>
                </div>
              </div>
            )}

            {words && (
              <div className="border-t border-gray-100">
                <p className="text-sm text-cyan-800 italic min-h-[1.5rem] whitespace-pre-wrap overflow-hidden">
                  $GOATAGI says: "{displayText}
                  {isTyping && <span className="animate-pulse">|</span>}"
                </p>
                <p className="text-xs text-gray-500">"~powered by ai16z"</p>
              </div>
            )}
          </div>
        </div>

        {/* Dice Display */}
        <div className="w-full scale-85">
          <Tooltip text="Current dice value that players are betting on" center>
            <div className="scale-100">{isLoading ? <DiceSkeleton /> : <Dice3D value={currentRoll} isRolling={isRolling} />}</div>
          </Tooltip>
        </div>

        {/* Balance & Betting Interface with Bettor Lists */}
        <div className="space-y-4">
          <div className="text-center">
            <div className="glass-card px-6 py-3 rounded-xl">
              <h2 className="text-xl font-bold">BET FOR NEXT DICE</h2>
              {publicKey && (
                <div className="text-sm">
                  <span className="text-gray-500">Balance:</span> <span className="font-medium">{balance?.toFixed(4)} SOL</span>
                </div>
              )}
            </div>
          </div>

          {/* Bettor Lists */}
          <div className="glass-card p-4 rounded-xl">
            <div className="grid grid-cols-2 gap-4">
              {/* Over Bets */}
              <div>
                <h3 className="text-sm font-medium text-blue-600 mb-2 flex items-center justify-center gap-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M12 20.25a.75.75 0 01-.75-.75V6.31l-5.47 5.47a.75.75 0 01-1.06-1.06l6.75-6.75a.75.75 0 011.06 0l6.75 6.75a.75.75 0 11-1.06 1.06l-5.47-5.47V19.5a.75.75 0 01-.75.75z" clipRule="evenodd" />
                  </svg>
                  OVER Bets
                </h3>
                <div className="h-[160px] overflow-y-auto no-scrollbar">{renderBetList(currentBets.over, "over")}</div>
              </div>

              {/* Under Bets */}
              <div>
                <h3 className="text-sm font-medium text-purple-600 mb-2 flex items-center justify-center gap-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M12 3.75a.75.75 0 01.75.75v13.19l5.47-5.47a.75.75 0 111.06 1.06l-6.75 6.75a.75.75 0 01-1.06 0l-6.75-6.75a.75.75 0 111.06-1.06l5.47 5.47V4.5a.75.75 0 01.75-.75z" clipRule="evenodd" />
                  </svg>
                  UNDER Bets
                </h3>
                <div className="h-[160px] overflow-y-auto no-scrollbar">{renderBetList(currentBets.under, "under")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Floating Betting Button */}
      <div className="fixed bottom-28 right-4 z-50">
        <button
          onClick={() => setBettingExpanded(!bettingExpanded)}
          className="px-4 py-3 rounded-xl shadow-lg bg-gradient-to-r from-blue-500 to-purple-500 backdrop-blur-sm active:scale-95 transition-all hover:shadow-xl border border-white/20 animate-pulse-slow animate-neon cursor-pointer"
        >
          <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-sm -z-10" />
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              <div className="flex flex-col">
                <span className="text-sm font-bold text-white group-hover:scale-105 transition-transform">Tap here to bet</span>
              </div>
            </div>
          </div>
        </button>
      </div>

      {/* Simplified Bottom Sheet Betting Interface */}
      {bettingExpanded && (
        <div className="fixed inset-0 z-50">
          <div className="fixed inset-0 bg-black/20 backdrop-blur-sm" onClick={() => setBettingExpanded(false)} />
          <div className="fixed inset-x-0 bottom-0 bg-white rounded-t-2xl p-4 animate-slide-up">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Place Your Bet</h2>
              <button onClick={() => setBettingExpanded(false)} className="text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-2 gap-4">
              {/* OVER Section */}
              <div className="space-y-2">
                <div className="text-center">
                  <span className="text-blue-600 font-bold">OVER = {overMultiplier ? `${overMultiplier}x` : "N/A"}</span>
                </div>
                <Button
                  className="w-full py-3 text-lg font-bold"
                  variant={currentRoll === 6 || !overMultiplier ? "disabled" : "over"}
                  onClick={() => {
                    handleBet("over", 0.1);
                    setBettingExpanded(false);
                  }}
                  disabled={isRolling || !overMultiplier || isBettingLocked}
                >
                  OVER
                  <div className="text-sm font-normal">0.1 SOL</div>
                </Button>
                <Button
                  className="w-full py-2 text-lg font-bold"
                  variant={currentRoll === 6 || !overMultiplier ? "disabled" : "over"}
                  onClick={() => {
                    handleBet("over", 0.01);
                    setBettingExpanded(false);
                  }}
                  disabled={isRolling || !overMultiplier || isBettingLocked}
                >
                  OVER
                  <div className="text-sm font-normal">0.01 SOL</div>
                </Button>
              </div>

              {/* UNDER Section */}
              <div className="space-y-2">
                <div className="text-center">
                  <span className="text-purple-600 font-bold">UNDER = {underMultiplier ? `${underMultiplier}x` : "N/A"}</span>
                </div>
                <Button
                  className="w-full py-3 text-lg font-bold"
                  variant={currentRoll === 1 || !underMultiplier ? "disabled" : "under"}
                  onClick={() => {
                    handleBet("under", 0.1);
                    setBettingExpanded(false);
                  }}
                  disabled={isRolling || !underMultiplier || isBettingLocked}
                >
                  UNDER
                  <div className="text-sm font-normal">0.1 SOL</div>
                </Button>
                <Button
                  className="w-full py-2 text-lg font-bold"
                  variant={currentRoll === 1 || !underMultiplier ? "disabled" : "under"}
                  onClick={() => {
                    handleBet("under", 0.01);
                    setBettingExpanded(false);
                  }}
                  disabled={isRolling || !underMultiplier || isBettingLocked}
                >
                  UNDER
                  <div className="text-sm font-normal">0.01 SOL</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
