import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Tooltip } from "./Tooltip";

export function WalletButton() {
  const { connected } = useWallet();

  return (
    <div className="relative">
      <Tooltip text={connected ? "Connected to Solana" : "Connect your Solana wallet"} position="bottom">
        <WalletMultiButton className="!bg-gradient-to-r from-blue-600 to-purple-600 !rounded-lg" />
      </Tooltip>
    </div>
  );
}
