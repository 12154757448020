export function Tooltip({ children, text, position = "top", center = false }) {
  const positions = {
    top: "bottom-full mb-2",
    bottom: "top-full mt-2",
    left: "right-full mr-2",
    right: "left-full ml-2",
  };

  return (
    <div className={`group ${center ? "block" : "inline-block"} relative`}>
      {children}
      <div className={`absolute ${positions[position]} left-1/2 -translate-x-1/2 hidden group-hover:block z-50`}>
        <div className="bg-gray-900 text-white text-xs rounded py-1 px-2 whitespace-nowrap">{text}</div>
      </div>
    </div>
  );
}
