export function HistoryTab({ winningHistory, isLoading, renderWinnerList }) {
  return (
    <div className="p-3">
      <div className="glass-card p-3 rounded-xl">
        <h3 className="text-lg font-medium mb-3">Last Round Winners</h3>
        <div className="h-[calc(100vh-280px)] overflow-y-auto no-scrollbar">{renderWinnerList(winningHistory)}</div>
      </div>
    </div>
  );
}
