import { Skeleton } from "./Skeleton";

export function DiceSkeleton() {
  return (
    <div className="relative w-32 h-32 mx-auto">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-gray-200 rounded-lg shadow-xl transform rotate-12"></div>
      <div className="absolute inset-0 glass-card rounded-lg shadow-lg flex items-center justify-center">
        <Skeleton className="w-24 h-24" />
      </div>
    </div>
  );
}
