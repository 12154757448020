import axios from "axios";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

const BASE_URL = "https://api.dragoneyes.xyz/overunder/game";

export const gameService = {
  getCurrentGame: async () => {
    try {
      //console.log("fetching..");
      const response = await axios.get(`${BASE_URL}/getCurrentGame`);
      // console.log("response", response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch game data:", error);
      throw error;
    }
  },

  placeBet: async (params) => {
    const { wallet, amount, betType, gameId, signature } = params;
    try {
      const response = await axios.get(`${BASE_URL}/placeBet`, {
        params: {
          solanaWallet: wallet,
          betAmount: Math.floor(amount * LAMPORTS_PER_SOL),
          betType,
          gameId,
          signature,
        },
      });

      const { result, gameData } = response.data;
      // console.log("gameData", gameData);
      return {
        success: result,
        gameData,
      };
    } catch (error) {
      console.error("Failed to place bet:", error);
      throw error;
    }
  },
};
