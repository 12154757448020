import { atom } from "jotai";

// Game state atoms
export const currentRollAtom = atom(2);
export const previousRollsAtom = atom([]);
export const timeLeftAtom = atom(null);
export const isRollingAtom = atom(false);
export const lastRollAtom = atom(null);
export const lastResultAtom = atom(null);
export const hasFirstBetAtom = atom(false);
export const isBettingLockedAtom = atom(false);
export const gameIdAtom = atom(null);
export const nextSpinAtom = atom(null);
export const isSpinningAtom = atom(false);
export const overMultiplierAtom = atom(null);
export const underMultiplierAtom = atom(null);

// Betting state atoms
export const currentBetsAtom = atom({
  over: [],
  under: [],
});
export const lastWinnersAtom = atom([]);
export const winningHistoryAtom = atom([]);

// Derived atom untuk payout
export const payoutsAtom = atom((get) => {
  const overMultiplier = get(overMultiplierAtom);
  const underMultiplier = get(underMultiplierAtom);

  return {
    over: overMultiplier,
    under: underMultiplier,
  };
});
