import React from "react";
import { Modal } from "./Modal";
import { motion } from "framer-motion";
import { toast } from "react-hot-toast";

export function BetStatusModal({
  isOpen,
  status,
  signature,
  amount,
  type,
  onClose,
  balance,
}) {
  const getStatusContent = () => {
    switch (status) {
      case "processing":
        return (
          <div className="text-center font-vt323">
            <div className="relative w-12 h-12 mx-auto mb-4">
              <motion.div
                className="absolute inset-0"
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              >
                <svg
                  className="w-full h-full text-blue-500"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              </motion.div>
            </div>
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Processing Your Bet
            </h3>
            <p className="text-base text-gray-500">
              Please wait while we process your transaction...
            </p>
            {signature && (
              <p className="mt-2 text-sm text-gray-400">
                Tx: {signature.slice(0, 8)}...{signature.slice(-8)}
              </p>
            )}
          </div>
        );

      case "insufficient_balance":
        return (
          <div className="text-center font-vt323">
            <div className="mx-auto mb-4 w-12 h-12 text-red-500">
              <svg
                className="w-full h-full"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <h3 className="text-xl font-medium text-gray-900 mb-4">
              Insufficient Balance
            </h3>
            <div className="space-y-2 text-lg">
              <p className="text-gray-700">
                Need:{" "}
                <span className="font-medium text-red-600">{amount} SOL</span>
              </p>
              <p className="text-gray-700">
                Have:{" "}
                <span className="font-medium text-green-600">
                  {balance?.toFixed(4)} SOL
                </span>
              </p>
              <p className="text-gray-700">
                Missing:{" "}
                <span className="font-medium text-red-600">
                  {(amount - (balance || 0)).toFixed(4)} SOL
                </span>
              </p>
            </div>
            <button
              onClick={onClose}
              className="mt-6 px-6 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors text-lg"
            >
              Close
            </button>
          </div>
        );

      case "success":
        return (
          <div
            className="text-center font-vt323 cursor-pointer"
            onClick={onClose}
          >
            <div className="mx-auto mb-4 w-12 h-12 text-green-500">
              <svg
                className="w-full h-full"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Bet Placed Successfully!
            </h3>
            <p className="text-lg text-gray-500">
              {amount} SOL on {type.toUpperCase()}
            </p>
            {signature && (
              <p className="mt-2 text-sm text-gray-400">
                Tx: {signature.slice(0, 8)}...{signature.slice(-8)}
              </p>
            )}
            <p className="mt-4 text-sm text-gray-400">Tap anywhere to close</p>
          </div>
        );

      case "error":
        return (
          <div className="text-center font-vt323">
            <div className="mx-auto mb-4 w-12 h-12 text-red-500">
              <svg
                className="w-full h-full"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Transaction Failed
            </h3>
            <p className="text-base text-gray-500">Please try again</p>
            <button
              onClick={onClose}
              className="mt-4 px-6 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors text-lg"
            >
              Close
            </button>
          </div>
        );

      case "api_error":
        return (
          <div className="text-center font-vt323">
            <div className="mx-auto mb-4 w-12 h-12 text-red-500">
              <svg
                className="w-full h-full"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <h3 className="text-xl font-medium text-gray-900 mb-4">
              Transaction Sent But Bet Failed
            </h3>
            <div className="space-y-2 text-lg">
              <p className="text-gray-700">
                Your transaction was successful, but we encountered an issue
                placing your bet.
              </p>
              <p className="text-gray-700">
                Please contact support with this transaction ID:
              </p>
              <div className="relative mt-2">
                <div className="flex items-center gap-2 bg-gray-100 p-2 rounded">
                  <div className="overflow-x-auto whitespace-nowrap no-scrollbar text-sm font-mono text-gray-600">
                    {signature}
                  </div>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(signature);
                      toast.success("Transaction ID copied!");
                    }}
                    className="shrink-0 p-1.5 text-gray-500 hover:text-gray-700 transition-colors"
                    aria-label="Copy to clipboard"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-6 space-y-2">
              <a
                href="https://t.me/goatagi"
                target="_blank"
                rel="noopener noreferrer"
                className="block px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors text-lg"
              >
                Contact Support
              </a>
              <button
                onClick={onClose}
                className="px-6 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors text-lg w-full"
              >
                Close
              </button>
            </div>
          </div>
        );

      case "session_closed":
        return (
          <div className="text-center font-vt323">
            <div className="mx-auto mb-4 w-12 h-12 text-yellow-500">
              <svg
                className="w-full h-full"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h3 className="text-xl font-medium text-gray-900 mb-4">
              Round Already Closed
            </h3>
            <div className="space-y-2 text-lg">
              <p className="text-gray-700">
                Your bet was a bit late for this round!
              </p>
              <p className="text-gray-700">
                Don't worry, your funds have been automatically refunded.
              </p>
              <div className="relative mt-2">
                <div className="flex items-center gap-2 bg-gray-100 p-2 rounded">
                  <div className="overflow-x-auto whitespace-nowrap no-scrollbar text-sm font-mono text-gray-600">
                    {signature}
                  </div>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(signature);
                      toast.success("Transaction ID copied!");
                    }}
                    className="shrink-0 p-1.5 text-gray-500 hover:text-gray-700 transition-colors"
                    aria-label="Copy to clipboard"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <button
              onClick={onClose}
              className="mt-6 px-6 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors text-lg w-full"
            >
              Close
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={
        status === "success" ||
        status === "error" ||
        status === "insufficient_balance"
          ? onClose
          : undefined
      }
    >
      {getStatusContent()}
    </Modal>
  );
}
