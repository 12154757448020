import { useState, useEffect } from "react";

export function useTypewriter(text, speed = 50) {
  const [displayText, setDisplayText] = useState("");
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    // Reset state
    setDisplayText("");
    setIsTyping(true);

    // Jika tidak ada text, return early
    if (!text) {
      setIsTyping(false);
      return;
    }

    // Konversi text ke array dan tambahkan ke queue
    const queue = Array.from(text);
    let currentText = "";

    const typeNextCharacter = () => {
      if (queue.length > 0) {
        const nextChar = queue.shift();
        currentText += nextChar;
        setDisplayText(currentText);

        if (queue.length === 0) {
          setIsTyping(false);
        }
      }
    };

    // Setup interval untuk typing
    const intervalId = setInterval(typeNextCharacter, speed);

    // Cleanup
    return () => {
      clearInterval(intervalId);
      setIsTyping(false);
    };
  }, [text, speed]);

  return { displayText, isTyping };
}
