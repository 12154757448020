import { WalletProvider } from "./providers/WalletProvider";
import DiceGame from "./components/DiceGame";

function App() {
  return (
    <WalletProvider>
      <main className="App">
        <DiceGame />
      </main>
    </WalletProvider>
  );
}

export default App;
