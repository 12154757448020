import { Modal } from "./Modal";
import { CopyableAddress } from "./CopyableAddress";

export function AboutModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="large">
      <div className="font-vt323">
        <div className="space-y-8 text-xl md:text-2xl">
          {/* About Section */}
          <section className="glass-card p-6 rounded-xl">
            <h3 className="text-3xl md:text-4xl font-bold mb-4 text-blue-600">
              About
            </h3>
            <div className="space-y-4 text-gray-700">
              <p>
                OVERUNDER is a savage gambling arena powered by $GOATAGI, the
                first deflationary gambling token on Solana that burns with
                every bet, all thanks to our MVP mascot—the Artificial Goat
                Intelligence 🐐🔥
              </p>
              <p>
                Winners receive 100% of their rewards in SOL, while 2.5% of
                every bet is used to buy and burn $GOATAGI—creating constant
                deflationary pressure.
              </p>
              <p className="font-medium mb-1">Contract Address:</p>
              <div className="flex items-start justify-start text-md">
                <CopyableAddress
                  address="33a14qXWo1MB7uXtTh5ifyvox7FdGRPQbsws41gfpump"
                  truncate={true}
                  isMobile={true}
                />
              </div>
            </div>
          </section>
          <section className="glass-card p-6 rounded-xl">
            <h3 className="text-3xl md:text-4xl font-bold mb-4 text-purple-600">
              Whitepaper
            </h3>
            <p className="text-gray-700">
              <a
                href="/GOAT_ARENA_A-Memecoin-Prediction-Market.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-gray-900 text-lg underline underline-offset-4 transition-colors"
              >
                Whitepaper
              </a>
            </p>
          </section>
          {/* Game Vibe Section */}
          <section className="glass-card p-6 rounded-xl">
            <h3 className="text-3xl md:text-4xl font-bold mb-4 text-purple-600">
              What's the vibe of the game?
            </h3>
            <p className="text-gray-700">
              It's a dice game fueled by on-chain randomness. Whenever you place
              a bet, 2.5% of it buys and burns $GOATAGI tokens—win or lose,
              you're part of the burn show.
            </p>
          </section>

          {/* How to Play Section */}
          <section className="glass-card p-6 rounded-xl">
            <h3 className="text-3xl md:text-4xl font-bold mb-4 text-green-600">
              How to play:
            </h3>
            <ol className="list-decimal list-inside space-y-4 text-gray-700 ml-4">
              <li>Check the current dice number.</li>
              <li>
                <strong>Bet OVER</strong> if you think the next roll will be
                higher.
                <strong>Bet UNDER</strong> if you think it'll be lower.
                <div className="mt-2">
                  Payouts are dynamic, changing based on the current dice.
                </div>
              </li>
              <li>
                If you guessed right, congrats—<strong>you win</strong> 🎉
                <br />
                If you guessed wrong or it's a tie… bad luck,{" "}
                <strong>you lose</strong> 😢
              </li>
            </ol>
          </section>

          {/* Tokenomics Section */}
          <section className="glass-card p-6 rounded-xl">
            <h3 className="text-3xl md:text-4xl font-bold mb-4 text-red-600">
              Tokenomics
            </h3>
            <ul className="list-none space-y-4 text-gray-700">
              <li>
                <strong>Deflationary AF</strong>: Starting at 1B $GOATAGI
                supply.
              </li>
              <li>
                <strong>Burn Mechanics</strong>: 2.5% of every bet gets burned
                every dice roll after token launch
              </li>
              <li>
                More bets = faster burn = 🚀 <strong>UP ONLY</strong>.
              </li>
            </ul>
          </section>

          {/* Social Links */}
          <div className="flex justify-center gap-6">
            <a
              href="https://x.com/goatofgamblers"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
              Twitter 🐦
            </a>
            <a
              href="https://t.me/goatagi"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              Telegram 💬
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}
