import React, { useEffect, useState, useRef } from "react";
import { useAtom } from "jotai";
import { Button } from "./ui/Button";
import { Card } from "./ui/Card";
import {
  currentRollAtom,
  previousRollsAtom,
  timeLeftAtom,
  isRollingAtom,
  currentBetsAtom,
  lastResultAtom,
  isBettingLockedAtom,
  gameIdAtom,
  winningHistoryAtom,
} from "../atoms/gameAtoms";
import { Dice3D } from "./ui/Dice3D";
import { PreviousDice } from "./ui/PreviousDice";
import { Tooltip } from "./ui/Tooltip";
import { Skeleton } from "./ui/Skeleton";
import { DiceSkeleton } from "./ui/DiceSkeleton";
import toast, { Toaster } from "react-hot-toast";
import { BetConfirmDialog } from "./ui/BetConfirmDialog";
import { GameRules } from "./ui/GameRules";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { WalletButton } from "./ui/WalletButton";
import {
  PublicKey,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { HOUSE_WALLET_PUBKEY } from "../constants/wallet";
import { gameService } from "../services/gameService";
import { BetStatusModal } from "./ui/BetStatusModal";
import { RoundStatusModal } from "./ui/RoundStatusModal";
import { AboutModal } from "./ui/AboutModal";
import { TabNavigation } from "./ui/TabNavigation";
import { GameTab } from "./mobile/GameTab";
import { InfoTab } from "./mobile/InfoTab";
import { HistoryTab } from "./mobile/HistoryTab";
import { TabContent } from "./mobile/TabContent";
import { WelcomeModal } from "./ui/WelcomeModal";
import { formatNumber } from "../utils/formatNumber";
import { CopyableAddress } from "./ui/CopyableAddress";
import { useTypewriter } from "../hooks/useTypewriter";

export default function DiceGame() {
  const { publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();

  const [currentRoll, setCurrentRoll] = useAtom(currentRollAtom);
  const [previousRolls, setPreviousRolls] = useAtom(previousRollsAtom);
  const [timeLeft, setTimeLeft] = useAtom(timeLeftAtom);
  const [isRolling, setIsRolling] = useAtom(isRollingAtom);
  const [currentBets, setCurrentBets] = useAtom(currentBetsAtom);
  const [lastResult] = useAtom(lastResultAtom);
  const [isBettingLocked, setIsBettingLocked] = useAtom(isBettingLockedAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [betDialog, setBetDialog] = useState({
    isOpen: false,
    type: "",
    amount: 0,
  });
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [gameId, setGameId] = useAtom(gameIdAtom);
  const [overMultiplier, setOverMultiplier] = useState(false);
  const [underMultiplier, setUnderMultiplier] = useState(false);
  const [winningHistory, setWinningHistory] = useAtom(winningHistoryAtom);
  const [balance, setBalance] = useState(0);
  const [totalBets, setTotalBets] = useState(0);
  const [allTimeReward, setAllTimeReward] = useState(0);
  const [words, setWords] = useState(false);
  const [betStatus, setBetStatus] = useState({
    isOpen: false,
    status: "",
    signature: "",
    amount: 0,
    type: "",
  });
  const [roundStatus, setRoundStatus] = useState({
    isOpen: false,
    previousRoll: null,
    currentRoll: null,
    result: null,
    burnAmount: 0,
    sentAmount: 0,
  });
  const [lastProcessedDice, setLastProcessedDice] = useState(null);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [burnAmount, setBurnAmount] = useState(0);
  const [activeTab, setActiveTab] = useState("game");
  const [showWelcome, setShowWelcome] = useState(false);

  // Timer countdown
  useEffect(() => {
    if (timeLeft === null) return;

    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        if (timeLeft === 11) {
          setIsBettingLocked(true);
        }
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  // Simulasi loading saat komponen mount
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500); // Simulasi 1.5 detik loading

    return () => clearTimeout(timer);
  }, []);

  // Ganti variable fetching dengan useRef
  const fetchingRef = useRef(false);

  // Update useEffect untuk game data polling
  useEffect(() => {
    const fetchGameData = async () => {
      try {
        if (fetchingRef.current) return;
        fetchingRef.current = true;

        const data = await gameService.getCurrentGame();
        //console.log(data, "<<<<< latest data");
        setAllTimeReward((Number(data.allTimeReward) / 1e9).toFixed(3));
        setGameId(data.id);
        setCurrentRoll(data.dice);
        if (data.words) {
          setWords(data.words);
        }

        let totalBets = data.overBets.length + data.underBets.length;

        setOverMultiplier(data.overMultiplier);
        //console.log(data.overMultiplier, "<<<<<<< over");
        //console.log(data.underMultiplier, "<<<<<<< under");
        setUnderMultiplier(data.underMultiplier);
        //console.log(totalBets, "<<<<<<<tb");
        setBurnAmount(data.totalBurnt / 1e6);
        setTotalBets(totalBets);

        // Update current bets dengan format baru
        setCurrentBets({
          over: data.overBets.map((bet) => ({
            player: bet.solanaWalletAddress,
            amount: bet.amount / LAMPORTS_PER_SOL,
            timestamp: new Date(parseInt(bet.time) / 1000000).toISOString(),
            multiplier: bet.multiplier,
            totalReward: bet.totalReward / LAMPORTS_PER_SOL,
          })),
          under: data.underBets.map((bet) => ({
            player: bet.solanaWalletAddress,
            amount: bet.amount / LAMPORTS_PER_SOL,
            timestamp: new Date(parseInt(bet.time) / 1000000).toISOString(),
            multiplier: bet.multiplier,
            totalReward: bet.totalReward / LAMPORTS_PER_SOL,
          })),
        });

        // Update previous rolls
        if (data.lastHouseRoll?.length) {
          // Urutkan berdasarkan game ID secara descending (terbaru ke terlama)
          var sortedRoll = data.lastHouseRoll.sort((a, b) => {
            return parseInt(b[0], 10) - parseInt(a[0], 10);
          });

          // Ambil current dice dari data terbaru
          setCurrentRoll(data.dice);

          // Set previous rolls tanpa current dice (slice dari index 1)
          setPreviousRolls(sortedRoll.map((roll) => roll[1]));
        }

        // Update winning history
        if (data.previousWinners) {
          setWinningHistory(
            data.previousWinners.map((winner) => ({
              player: winner.solanaWalletAddress,
              betAmount: winner.amount / LAMPORTS_PER_SOL,
              winAmount: winner.totalReward / LAMPORTS_PER_SOL,
              stance: winner.bet === 1 || winner.bet === "1" ? "over" : "under",
              multiplier: winner.multiplier,
              timestamp: new Date(
                parseInt(winner.time) / 1000000
              ).toISOString(),
            }))
          );
        }
        var t = 0;
        // Handle time left calculation
        if (data.nextSpin && !data.is_spinning) {
          const timeLeftMs = parseInt(data.nextSpin) - Date.now();
          t = Math.max(0, Math.floor(timeLeftMs / 1000));
          setTimeLeft(t);
        } else {
          setTimeLeft(null);
        }

        if (totalBets > 0 && t <= 5) {
          //console.log("ROLLING TIME");

          setIsRolling(true);
          setIsBettingLocked(true);
        } else {
          setIsRolling(data.is_spinning);
          if (data.id > gameId) {
            setIsBettingLocked(false);
          }
        }

        // Cek perubahan roll untuk menampilkan round status
        if (
          data.dice !== lastProcessedDice &&
          !isRolling &&
          lastProcessedDice !== null
        ) {
          // console.log("Current Dice:", data.dice);
          // console.log("Last Dice:", lastProcessedDice);
          // console.log("Last Result:", data.lastResult);

          setRoundStatus({
            isOpen: true,
            previousRoll: lastProcessedDice,
            currentRoll: data.dice,
            result:
              data.lastResult ||
              (data.dice === lastProcessedDice
                ? "tie"
                : data.dice > lastProcessedDice
                ? "over"
                : "under"),
            burnAmount: Number(data.previousBurntToken) || 0,
            sentAmount: Number(data.previousSentToken) || 0,
          });

          // Auto close round status modal setelah 5 detik
          setTimeout(() => {
            setRoundStatus((prev) => ({ ...prev, isOpen: false }));
          }, 5000);
        }

        // Update lastProcessedDice setelah menampilkan modal
        setLastProcessedDice(data.dice);

        fetchingRef.current = false;
      } catch (error) {
        console.error("Failed to fetch game data:", error);
        fetchingRef.current = false;
      }
    };

    const interval = setInterval(fetchGameData, 1000);
    return () => {
      clearInterval(interval);
      fetchingRef.current = false;
    };
  }, [lastProcessedDice]);

  // Tambahkan fungsi retry untuk placebet
  const retryPlaceBet = async (retryCount = 3, params) => {
    for (let i = 0; i < retryCount; i++) {
      try {
        const result = await gameService.placeBet(params);
        if (result.success) return result;

        // Jika gagal tapi bukan karena error network, langsung throw
        if (
          !result.error?.includes("network") &&
          !result.error?.includes("timeout")
        ) {
          throw new Error(result.error);
        }
      } catch (error) {
        // Jika ini attempt terakhir, throw error
        if (i === retryCount - 1) throw error;

        // Tunggu sebentar sebelum retry (exponential backoff)
        await new Promise((resolve) =>
          setTimeout(resolve, Math.pow(2, i) * 1000)
        );
      }
    }
    throw new Error("Failed after retry attempts");
  };

  // Update handleBet function
  const handleBet = async (type, amount) => {
    if (!publicKey) {
      toast.error("Please connect your wallet");
      return;
    }

    // Buka modal dengan status processing
    setBetStatus({
      isOpen: true,
      status: "processing",
      amount,
      type,
    });

    try {
      // Validate amount
      if (amount <= 0) {
        throw new Error("Invalid bet amount");
      }

      // Check wallet balance with fee consideration
      const balance = await connection.getBalance(publicKey);
      const requiredBalance = Math.floor(amount * LAMPORTS_PER_SOL);
      const estimatedFee = 5000; // Estimasi fee sekitar 0.000005 SOL
      const totalRequired = requiredBalance + estimatedFee;

      if (balance < totalRequired) {
        setBetStatus({
          isOpen: true,
          status: "insufficient_balance",
          amount: amount,
          type: type,
          balance: balance / LAMPORTS_PER_SOL,
        });
        return;
      }

      // Get latest blockhash
      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash("confirmed");
      if (!blockhash || !lastValidBlockHeight) {
        throw new Error("Failed to get recent blockhash");
      }

      // Create transaction
      const transaction = new Transaction();

      // Set transaction properties
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      // Add transfer instruction
      transaction.add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(HOUSE_WALLET_PUBKEY),
          lamports: Math.floor(amount * LAMPORTS_PER_SOL),
        })
      );

      // Send transaction
      const signature = await sendTransaction(transaction, connection, {
        skipPreflight: false,
        preflightCommitment: "confirmed",
        maxRetries: 5,
      });

      // Update modal dengan signature
      setBetStatus((prev) => ({ ...prev, signature }));

      try {
        const confirmation = await connection.confirmTransaction(
          {
            signature,
            blockhash,
            lastValidBlockHeight,
          },
          "confirmed"
        );

        if (confirmation?.value?.err) {
          throw new Error(`Transaction failed: ${confirmation.value.err}`);
        }

        // Place bet through API dengan retry mechanism
        try {
          const { success, gameData, error } = await retryPlaceBet(3, {
            wallet: publicKey.toString(),
            amount,
            betType: type === "over" ? 1 : 2,
            gameId,
            signature,
          });

          if (success) {
            // Update game state dengan data terbaru
            setGameId(gameData.id);
            setCurrentRoll(gameData.dice);
            setOverMultiplier(gameData.overMultiplier);
            setUnderMultiplier(gameData.underMultiplier);

            // Update current bets
            setCurrentBets({
              over: gameData.overBets.map((bet) => ({
                player: bet.solanaWalletAddress,
                amount: bet.amount / LAMPORTS_PER_SOL,
                timestamp: new Date(parseInt(bet.time) / 1000000).toISOString(),
              })),
              under: gameData.underBets.map((bet) => ({
                player: bet.solanaWalletAddress,
                amount: bet.amount / LAMPORTS_PER_SOL,
                timestamp: new Date(parseInt(bet.time) / 1000000).toISOString(),
              })),
            });

            // Update timer jika ada nextSpin
            if (gameData.nextSpin) {
              const timeLeftMs = parseInt(gameData.nextSpin) - Date.now();
              setTimeLeft(Math.max(0, Math.floor(timeLeftMs / 1000)));
            }

            // Update modal status to success
            setBetStatus((prev) => ({ ...prev, status: "success" }));

            // Auto close setelah 3 detik, tapi user bisa close manual kapan saja
            setTimeout(() => {
              setBetStatus((prev) => {
                // Hanya close jika modal masih open dan statusnya masih success
                if (prev.isOpen && prev.status === "success") {
                  return { ...prev, isOpen: false };
                }
                return prev;
              });
            }, 3000);
          } else {
            if (error === "Game session is closed") {
              setBetStatus({
                isOpen: true,
                status: "session_closed",
                signature,
                amount,
                type,
              });
            } else {
              setBetStatus({
                isOpen: true,
                status: "api_error",
                signature,
                amount,
                type,
              });
            }
          }
        } catch (apiError) {
          // Tampilkan modal khusus untuk API error
          setBetStatus({
            isOpen: true,
            status: "api_error",
            signature,
            amount,
            type,
          });
          console.error("API Error:", apiError);
        }
      } catch (error) {
        // ... handle solana transaction error ...
      }
    } catch (error) {
      // ... handle other errors ...
    }
  };

  // New function to handle confirmed bet
  const handleConfirmBet = async () => {
    try {
      const { type, amount } = betDialog;

      // Get latest blockhash
      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash();

      // Create transaction
      const transaction = new Transaction({
        feePayer: publicKey,
        blockhash: blockhash,
        lastValidBlockHeight: lastValidBlockHeight,
      }).add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(HOUSE_WALLET_PUBKEY),
          lamports: amount * LAMPORTS_PER_SOL,
        })
      );

      const signature = await sendTransaction(transaction, connection);
      // console.log("Transaction requested with signature:", signature);

      const pendingToast = toast.loading("Confirming transaction...");

      try {
        const confirmation = await Promise.race([
          connection.confirmTransaction({
            signature,
            blockhash,
            lastValidBlockHeight,
          }),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Transaction expired")), 30000)
          ),
        ]);

        if (confirmation.value?.err) {
          toast.error("Transaction failed!", { id: pendingToast });
          throw new Error("Transaction failed!");
        }

        // Place bet through API
        const betResult = await gameService.placeBet({
          wallet: publicKey.toString(),
          amount,
          betType: type === "over" ? 1 : 2,
          gameId,
          signature,
        });

        if (betResult.success) {
          toast.success(
            `Bet placed successfully! Tx: ${signature.slice(0, 8)}...`,
            {
              id: pendingToast,
            }
          );
        } else {
          toast.error(betResult.error || "Failed to place bet", {
            id: pendingToast,
          });
          throw new Error(betResult.error);
        }
      } catch (error) {
        console.error("Bet failed:", error);
        toast.error(error.message || "Failed to place bet", {
          id: pendingToast,
        });
        throw error;
      }
    } catch (error) {
      console.error("Transaction failed:", error);
      toast.error("Failed to process transaction");
    } finally {
      setBetDialog({ isOpen: false, type: "", amount: 0 });
    }
  };

  // Tambahkan useEffect untuk memantau balance
  useEffect(() => {
    const getBalance = async () => {
      if (publicKey) {
        try {
          const bal = await connection.getBalance(publicKey);
          setBalance(bal / LAMPORTS_PER_SOL);
        } catch (error) {
          console.error("Failed to get balance:", error);
        }
      }
    };

    getBalance();
    // Update balance setiap 5 detik
    const interval = setInterval(getBalance, 5000);
    return () => clearInterval(interval);
  }, [publicKey, connection]);

  // Update renderBetList function untuk tampilan bettor list
  const renderBetList = (bets, type) => (
    <div className="space-y-2">
      {bets.length > 0 ? (
        bets.map((bet, index) => (
          <div
            key={index}
            className={`bg-gradient-to-r ${
              type === "over" ? "from-blue-500/10" : "from-purple-500/10"
            } to-transparent p-2 rounded-lg border ${
              type === "over"
                ? "border-blue-500/20 hover:border-blue-500/40"
                : "border-purple-500/20 hover:border-purple-500/40"
            } transition-colors`}
          >
            <div className="flex items-center justify-between gap-1">
              <span className="text-sm font-medium truncate max-w-[100px]">
                {bet.player.slice(0, 4)}...{bet.player.slice(-4)}
              </span>
              <div className="flex items-center shrink-0">
                <span
                  className={`${
                    type === "over" ? "text-blue-400" : "text-purple-400"
                  } font-medium text-base`}
                >
                  {bet.amount === "0.01"
                    ? "0.01"
                    : bet.amount === "0.1"
                    ? "0.1"
                    : bet.amount.toFixed(3)}
                </span>
                <span
                  className={`${
                    type === "over" ? "text-blue-400/70" : "text-purple-400/70"
                  } text-sm ml-1`}
                >
                  SOL
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center py-8 text-gray-500">
          <span className="text-base">No bets yet</span>
        </div>
      )}
    </div>
  );

  // Update bagian tampilan winner di mobile dan desktop layout
  const renderWinnerList = (winners) => (
    <div className="space-y-3">
      {winners.length > 0 ? (
        winners.map((winner, index) => (
          <div
            key={index}
            className={`relative p-4 rounded-lg border transition-colors ${
              winner.stance === "over"
                ? "bg-blue-50/50 border-blue-200 hover:border-blue-300"
                : "bg-purple-50/50 border-purple-200 hover:border-purple-300"
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-1">
                <span
                  className={`text-base ${
                    winner.stance === "over"
                      ? "text-blue-600"
                      : "text-purple-600"
                  }`}
                >
                  {index + 1}.
                </span>
                <span className="font-medium text-lg">
                  {winner.player.slice(0, 4)}...{winner.player.slice(-4)}
                </span>
              </div>
              <span className="text-green-600 font-medium text-lg">
                {winner.winAmount.toFixed(3)} SOL
              </span>
            </div>
            <div className=" text-sm text-gray-600 flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <span className="font-bold">
                  Bet {winner.betAmount.toFixed(3)} SOL on
                </span>
                <span
                  className={`px-3 py-1 rounded text-sm font-medium ${
                    winner.stance === "over"
                      ? "bg-blue-100 text-blue-800"
                      : "bg-purple-100 text-purple-800"
                  }`}
                >
                  {winner.stance?.toUpperCase()}
                </span>
              </div>
              <span className="text-yellow-600 text-base font-medium">
                {winner.multiplier}x
              </span>
            </div>
            <div
              className={`absolute left-0 top-0 bottom-0 w-1.5 rounded-l-lg ${
                winner.stance === "over" ? "bg-blue-400" : "bg-purple-400"
              }`}
            />
          </div>
        ))
      ) : (
        <div className="text-gray-500 text-center py-6">
          <span className="text-lg">
            Everyone guessed wrong on the last round baaah 🐐
          </span>
        </div>
      )}
    </div>
  );

  // Tambahkan fungsi untuk handle close modal
  const handleCloseBetModal = () => {
    setBetStatus((prev) => ({ ...prev, isOpen: false }));
  };

  // Check if first visit when component mounts
  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisitedOverUnder");
    if (!hasVisited) {
      setShowWelcome(true);
    }
  }, []);

  // Tambahkan hook useTypewriter
  const { displayText, isTyping } = useTypewriter(words || "", 40);

  return (
    <>
      {/* Wrapper untuk konten utama dengan padding bottom untuk footer */}
      <div className="pb-[70px]">
        {" "}
        {/* Tambah padding untuk akomodasi footer + tab nav */}
        <div className="max-w-6xl sm:max-w-full mx-auto p-4 sm:space-y-6 space-y-4 font-vt323">
          {/* Previous Dice History */}
          <div className="glass-card p-4 rounded-xl">
            {/* Mobile Layout */}
            <div className="md:hidden space-y-4">
              {/* Total Rewards - Mobile */}
              <div className="flex justify-between items-center border-gray-100">
                <div className="flex flex-col gap-1">
                  <div className="text-xs font-medium text-gray-500">
                    Total rewards distributed
                  </div>
                  <span className="text-lg font-medium text-green-700 text-center">
                    {allTimeReward} SOL
                  </span>
                </div>

                <div className="flex items-center gap-2">
                  <WalletButton />
                </div>
              </div>

              {/* Previous Dice - Mobile */}
              <div className="">
                <div className="text-base text-gray-600 font-medium mb-1">
                  Previous Rolls
                </div>
                <div className="overflow-x-auto no-scrollbar">
                  <div className="flex gap-3 pb-1 pt-2">
                    {isLoading
                      ? Array(5)
                          .fill(0)
                          .map((_, index) => (
                            <Skeleton
                              key={index}
                              className="w-10 h-10 flex-shrink-0"
                            />
                          ))
                      : previousRolls.map((roll, index) => (
                          <div key={index} className="relative flex-shrink-0">
                            <PreviousDice value={roll} />
                            {index === 0 && (
                              <div className="absolute -top-1 -right-1 w-3 h-3 bg-green-500 rounded-full animate-pulse">
                                <div className="absolute inset-0 bg-green-500 rounded-full animate-ping opacity-75" />
                              </div>
                            )}
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Desktop Layout - Tetap sama */}
            <div className="hidden md:flex justify-between items-center">
              {/* Dice list */}
              <div className="flex flex-1 items-center gap-4">
                <Tooltip
                  text="History of previous dice rolls"
                  position="bottom"
                >
                  <h3 className="text-gray-700 font-bold text-base whitespace-nowrap pr-4 border-r border-gray-200">
                    PREVIOUS DICE
                  </h3>
                </Tooltip>

                <div className="flex-1 overflow-x-auto no-scrollbar">
                  <div className="flex gap-3 pb-2 pt-1 overscroll-bounce md:max-w-[calc(100vw-800px)]">
                    {isLoading
                      ? Array(5)
                          .fill(0)
                          .map((_, index) => (
                            <Skeleton
                              key={index}
                              className="w-10 h-10 flex-shrink-0"
                            />
                          ))
                      : previousRolls.map((roll, index) => (
                          <div key={index} className="relative flex-shrink-0">
                            <PreviousDice value={roll} />
                            {index === 0 && (
                              <div className="absolute -top-1 -right-1 w-3 h-3 bg-green-500 rounded-full animate-pulse">
                                <div className="absolute inset-0 bg-green-500 rounded-full animate-ping opacity-75" />
                              </div>
                            )}
                          </div>
                        ))}
                  </div>
                </div>
              </div>

              {/* Divider untuk desktop */}
              <div className="w-px h-10 bg-gray-200 mx-4" />

              {/* Total Rewards distributed in SOL & Wallet */}
              <div className="flex items-center justify-between gap-0 md:min-w-[20%]">
                <div className="px-6 py-0 rounded-xl">
                  <div className="items-center text-center text-xs md:text-sm">
                    Total rewards distributed
                  </div>
                  <h3 className="text-xl md:text-2xl text-center font-medium mb-4 flex items-center">
                    <span className="text-green-700">{allTimeReward} SOL</span>
                  </h3>
                </div>
                <WalletButton />
              </div>
            </div>
          </div>

          {/* Mobile Layout (hidden on desktop) */}
          <div className="md:hidden">
            {/* Tab Content dengan animasi */}
            <div className="mt-2">
              <TabContent isActive={activeTab === "game"}>
                <GameTab
                  timeLeft={timeLeft}
                  isRolling={isRolling}
                  currentRoll={currentRoll}
                  lastResult={lastResult}
                  balance={balance}
                  publicKey={publicKey}
                  isLoading={isLoading}
                  totalBets={totalBets}
                  overMultiplier={overMultiplier}
                  underMultiplier={underMultiplier}
                  isBettingLocked={isBettingLocked}
                  handleBet={handleBet}
                  currentBets={currentBets}
                  renderBetList={renderBetList}
                  words={words}
                />
              </TabContent>

              <TabContent isActive={activeTab === "info"}>
                <InfoTab burnAmount={burnAmount} />
              </TabContent>

              <TabContent isActive={activeTab === "history"}>
                <HistoryTab
                  winningHistory={winningHistory}
                  isLoading={isLoading}
                  renderWinnerList={renderWinnerList}
                />
              </TabContent>
            </div>
          </div>

          {/* Desktop Layout (3 columns) */}
          <div className="hidden md:grid md:grid-cols-12 gap-6">
            {/* Left Column - Last Round Winners (3 kolom) */}
            <div className="col-span-3 space-y-4">
              <Card className="p-4">
                <h3 className="text-xl font-medium mb-4 flex items-center">
                  🏆 Last Round Winners
                </h3>
                <div className="h-[700px] overflow-y-auto no-scrollbar">
                  {renderWinnerList(winningHistory)}
                </div>
              </Card>
            </div>

            {/* Center Column - Dice Display (6 kolom) */}
            <div className="col-span-6 flex flex-col items-center">
              {/* Timer dan Words Container */}
              <div className="w-full grid grid-cols-1 gap-4">
                {/* Timer Section */}
                <Tooltip text="Time remaining until next dice roll" center>
                  <div className="glass-card px-8 py-4 rounded-xl">
                    <div className="flex flex-col items-center">
                      <h2 className="text-3xl font-semibold">
                        <p
                          className={`${
                            timeLeft <= 10 && totalBets > 0
                              ? "text-red-500 timer-pulse"
                              : "text-gray-500"
                          } font-bold`}
                        >
                          {isRolling
                            ? "ROLLING..."
                            : totalBets === 0
                            ? "WAITING FOR FIRST BET"
                            : `NEXT ROLL in ${timeLeft}s`}
                        </p>
                      </h2>
                      {lastResult && (
                        <div
                          className={`mt-2 text-2xl font-bold ${
                            lastResult === "over"
                              ? "text-blue-500"
                              : lastResult === "under"
                              ? "text-purple-500"
                              : "text-gray-600"
                          }`}
                        >
                          <div className="flex items-center justify-center gap-2">
                            <p className="animate-bounce">
                              {lastResult === "over"
                                ? "↑ OVER WINS!"
                                : lastResult === "under"
                                ? "↓ UNDER WINS!"
                                : "= TIE - GOAT WINS!"}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Tooltip>

                {/* Words Section dalam card terpisah */}
                {words && (
                  <div className="glass-card px-8 py-4 rounded-xl">
                    <div className="text-center">
                      <p className="text-md text-cyan-800 italic min-h-[1.5rem] whitespace-pre-wrap overflow-hidden">
                        $GOATAGI says: "{displayText}
                        {isTyping && <span className="animate-pulse">|</span>}"
                      </p>
                      <p className="text-sm text-gray-500">
                        "~powered by ai16z"
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {/* Dice Display */}
              <div className="relative mt-72">
                <Tooltip
                  text="Current dice value that players are betting on"
                  center
                >
                  <div className="scale-[1.7]">
                    {isLoading ? (
                      <DiceSkeleton />
                    ) : (
                      <Dice3D value={currentRoll} isRolling={isRolling} />
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>

            {/* Right Column - Betting Interface (3 kolom) */}
            <div className="col-span-3 space-y-4">
              <div className="text-center w-full space-y-2">
                <h2 className="text-xl font-bold">BET FOR NEXT DICE</h2>
                {publicKey && (
                  <div className="text-sm">
                    <span className="text-gray-500">Balance:</span>{" "}
                    <span className="font-medium">
                      {balance.toFixed(4)} SOL
                    </span>
                  </div>
                )}
                <div className="glass-card px-6 py-3 rounded-xl">
                  2.5% of bet will be used to{" "}
                  <span className="text-green-800">BUY</span> and{" "}
                  <span className="text-red-700">BURN</span> $GOATAGI
                </div>
                <div className="glass-card px-6 py-3 rounded-xl">
                  Total $GOATAGI Burnt:{" "}
                  <span className="text-red-600 font-medium">
                    {formatNumber(burnAmount)} 🔥
                  </span>
                </div>
                <div className="flex flex-col items-center space-y-1">
                  <div className="text-gray-600 font-medium">
                    $GOATAGI Contract Address:
                  </div>
                  <CopyableAddress
                    address="33a14qXWo1MB7uXtTh5ifyvox7FdGRPQbsws41gfpump"
                    truncate={false}
                  />
                </div>
              </div>
              <Card className="p-4 h-[700px]">
                <div className="grid grid-cols-2 gap-6 h-full">
                  {/* Over Section */}
                  <div className="flex flex-col h-full">
                    <Tooltip text="Bet wins if next roll is higher than current roll">
                      <h3 className="text-center font-medium text-xl mb-3">
                        OVER {overMultiplier ? `= ${overMultiplier}x` : "(N/A)"}
                      </h3>
                    </Tooltip>
                    <div className="space-y-3 mb-4">
                      <Button
                        className="w-full text-lg py-3"
                        variant={
                          currentRoll === 6 || !overMultiplier
                            ? "disabled"
                            : "over"
                        }
                        onClick={() => handleBet("over", 0.01)}
                        disabled={
                          isRolling || !overMultiplier || isBettingLocked
                        }
                      >
                        OVER (0.01 SOL)
                      </Button>
                      <Button
                        className="w-full text-lg py-3"
                        variant={
                          currentRoll === 6 || !overMultiplier
                            ? "disabled"
                            : "over"
                        }
                        onClick={() => handleBet("over", 0.1)}
                        disabled={
                          isRolling || !overMultiplier || isBettingLocked
                        }
                      >
                        OVER (0.1 SOL)
                      </Button>
                    </div>
                    <div className="flex-1 overflow-hidden">
                      <h4 className="font-medium mb-2 flex items-center text-base">
                        <span className="text-blue-400 mr-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 20.25a.75.75 0 01-.75-.75V6.31l-5.47 5.47a.75.75 0 01-1.06-1.06l6.75-6.75a.75.75 0 011.06 0l6.75 6.75a.75.75 0 11-1.06 1.06l-5.47-5.47V19.5a.75.75 0 01-.75.75z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        <div className="flex items-center">
                          <span className="text-base">BET OVER</span>
                          <span className="text-base ml-2">
                            ({overMultiplier ? `${overMultiplier}x` : "N/A"})
                          </span>
                        </div>
                      </h4>
                      <div className="overflow-y-auto h-[400px] no-scrollbar">
                        {renderBetList(currentBets.over, "over")}
                      </div>
                    </div>
                  </div>

                  {/* Under Section */}
                  <div className="flex flex-col h-full">
                    <Tooltip text="Bet wins if next roll is lower than current roll">
                      <h3 className="text-center font-medium text-xl mb-3">
                        UNDER{" "}
                        {underMultiplier ? `= ${underMultiplier}x` : "(N/A)"}
                      </h3>
                    </Tooltip>
                    <div className="space-y-3 mb-4">
                      <Button
                        className="w-full text-lg py-3"
                        variant={
                          currentRoll === 1 || !underMultiplier
                            ? "disabled"
                            : "under"
                        }
                        onClick={() => handleBet("under", 0.01)}
                        disabled={
                          isRolling || !underMultiplier || isBettingLocked
                        }
                      >
                        UNDER (0.01 SOL)
                      </Button>
                      <Button
                        className="w-full text-lg py-3"
                        variant={
                          currentRoll === 1 || !underMultiplier
                            ? "disabled"
                            : "under"
                        }
                        onClick={() => handleBet("under", 0.1)}
                        disabled={
                          isRolling || !underMultiplier || isBettingLocked
                        }
                      >
                        UNDER (0.1 SOL)
                      </Button>
                    </div>
                    <div className="flex-1 overflow-hidden">
                      <h4 className="font-medium mb-2 flex items-center text-base">
                        <span className="text-purple-400 mr-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 3.75a.75.75 0 01.75.75v13.19l5.47-5.47a.75.75 0 111.06 1.06l-6.75 6.75a.75.75 0 01-1.06 0l-6.75-6.75a.75.75 0 111.06-1.06l5.47 5.47V4.5a.75.75 0 01.75-.75z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        <div className="flex items-center">
                          <span className="text-base">BET UNDER</span>
                          <span className="text-base ml-2">
                            ({underMultiplier ? `${underMultiplier}x` : "N/A"})
                          </span>
                        </div>
                      </h4>
                      <div className="overflow-y-auto h-[450px] no-scrollbar">
                        {renderBetList(currentBets.under, "under")}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* Add TabNavigation for mobile */}
      <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} />

      {/* Add Dialog and Toaster */}
      <BetConfirmDialog
        isOpen={betDialog.isOpen}
        onClose={() => setBetDialog({ isOpen: false, type: "", amount: 0 })}
        onConfirm={handleConfirmBet}
        betAmount={betDialog.amount}
        betType={betDialog.type}
      />
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 3000,
          // Default style untuk semua toast
          style: {
            background: "#333",
            color: "#fff",
          },
          // Custom style untuk setiap tipe toast
          success: {
            style: {
              background: "#fff",
              color: "#000",
              border: "1px solid #E5E7EB",
            },
            icon: "✅",
          },
          error: {
            style: {
              background: "#333",
              color: "#fff",
              border: "1px solid #B91C1C",
            },
            icon: "❌",
          },
          // Custom animations
          className: "transform transition-all",
        }}
      />

      {/* Add GameRules component */}
      <GameRules isOpen={isRulesOpen} onClose={() => setIsRulesOpen(false)} />

      {/* Add modals */}
      <BetStatusModal
        isOpen={betStatus.isOpen}
        status={betStatus.status}
        signature={betStatus.signature}
        amount={betStatus.amount}
        type={betStatus.type}
        balance={balance}
        onClose={handleCloseBetModal}
      />

      <RoundStatusModal
        isOpen={roundStatus.isOpen}
        previousRoll={roundStatus.previousRoll}
        currentRoll={roundStatus.currentRoll}
        result={roundStatus.result}
        burnAmount={roundStatus.burnAmount}
        sentAmount={roundStatus.sentAmount}
      />

      {/* Add AboutModal */}
      <AboutModal isOpen={isAboutOpen} onClose={() => setIsAboutOpen(false)} />

      {/* Add WelcomeModal */}
      <WelcomeModal
        isOpen={showWelcome}
        onClose={() => setShowWelcome(false)}
        burnAmount={burnAmount}
      />

      {/* Sticky Footer */}
      <footer className="fixed bottom-0 left-0 right-0 bg-white/95 backdrop-blur-sm border-t border-gray-200 py-2 z-20">
        <div className="max-w-6xl mx-auto px-4">
          {/* Desktop Layout */}
          <div className="hidden md:flex items-center justify-center gap-6 font-vt323">
            {/* Contract Address */}
            <div className="flex items-center gap-2 text-gray-600">
              <span className="text-sm">Contract:</span>
              <CopyableAddress
                address="33a14qXWo1MB7uXtTh5ifyvox7FdGRPQbsws41gfpump"
                truncate={true}
              />
            </div>

            {/* Divider */}
            <span className="text-gray-300">|</span>

            {/* Navigation Links */}
            <button
              onClick={() => setIsRulesOpen(true)}
              className="text-gray-600 hover:text-gray-900 text-lg underline underline-offset-4 transition-colors"
            >
              How to Play
            </button>

            <button
              onClick={() => setIsAboutOpen(true)}
              className="text-gray-600 hover:text-gray-900 text-lg underline underline-offset-4 transition-colors"
            >
              About
            </button>
            <a
              href="/GOAT_ARENA_A-Memecoin-Prediction-Market.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-gray-900 transition-colors"
              aria-label="Twitter"
            >
              Whitepaper
            </a>

            {/* Divider */}
            <span className="text-gray-300">|</span>

            {/* Social Links */}
            <a
              href="https://x.com/goatofgamblers"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-gray-900 transition-colors"
              aria-label="Twitter"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
              </svg>
            </a>

            <a
              href="https://t.me/goatagi"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-gray-900 transition-colors"
              aria-label="Telegram"
            >
              <svg
                className="w-5 h-5"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.223-.554.223l.198-2.8 5.106-4.615c.223-.198-.054-.308-.346-.11L7.93 13.65l-2.694-.918c-.586-.183-.596-.586.124-.87l10.515-4.054c.487-.18.913.106.72.414z" />
              </svg>
            </a>
          </div>

          {/* Mobile Layout */}
          <div className="md:hidden font-vt323">
            <div className="flex items-center justify-center gap-6">
              <button
                onClick={() => setIsRulesOpen(true)}
                className="text-gray-600 hover:text-gray-900 text-lg underline underline-offset-4 transition-colors"
              >
                How to Play
              </button>

              <button
                onClick={() => setIsAboutOpen(true)}
                className="text-gray-600 hover:text-gray-900 text-lg underline underline-offset-4 transition-colors"
              >
                About
              </button>
              

              {/* Divider */}
              <span className="text-gray-300">|</span>

              {/* Social Links */}
              <a
                href="https://x.com/goatofgamblers"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-gray-900 transition-colors"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                </svg>
              </a>

              <a
                href="https://t.me/goatagi"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-gray-900 transition-colors"
              >
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.223-.554.223l.198-2.8 5.106-4.615c.223-.198-.054-.308-.346-.11L7.93 13.65l-2.694-.918c-.586-.183-.596-.586.124-.87l10.515-4.054c.487-.18.913.106.72.414z" />
                </svg>
              </a>

              {/* Copyright */}
              <span className="text-xs text-gray-400">© 2024</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
