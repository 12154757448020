import React, { useState } from "react";
import { Modal } from "./Modal";
import { motion } from "framer-motion";
import { formatNumber } from "../../utils/formatNumber";
import { CopyableAddress } from "./CopyableAddress";

export function WelcomeModal({ isOpen, onClose, burnAmount }) {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem("hasVisitedOverUnder", "true");
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="large">
      <div className="font-vt323">
        <motion.div initial={{ scale: 0.9, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: 0.3 }} className="space-y-6">
          <div className="text-center">
            <h2 className="text-4xl font-bold bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text">Welcome to OVER/UNDER</h2>
            <p className="text-gray-600 mt-2">by Artificial Goat Intelligence</p>
          </div>

          <div className="space-y-4">
            <div className="glass-card p-4 rounded-xl">
              <h3 className="font-bold text-xl mb-4 text-purple-600">$GOATAGI Token</h3>
              <div className="space-y-4 text-gray-700">
                <div className="flex items-center justify-center gap-2 text-lg">
                  <span className="text-red-500">🔥</span>
                  <span>2.5% of every bet buys & burns $GOATAGI</span>
                </div>

                <div className="glass-card px-6 py-3 rounded-xl bg-red-50/50">
                  <div className="text-center">
                    <p className="text-gray-600 text-sm">Total $GOATAGI Burnt:</p>
                    <p className="text-2xl font-bold text-red-600">{formatNumber(burnAmount)} 🔥</p>
                  </div>
                </div>

                <div className="text-sm bg-gray-50 p-3 rounded">
                  <p className="font-medium mb-1">Contract Address:</p>
                  <CopyableAddress address="33a14qXWo1MB7uXtTh5ifyvox7FdGRPQbsws41gfpump" />
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-center gap-2">
              <input type="checkbox" id="dontShowAgain" checked={dontShowAgain} onChange={(e) => setDontShowAgain(e.target.checked)} className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500" />
              <label htmlFor="dontShowAgain" className="text-gray-600 select-none cursor-pointer">
                Don't show this again
              </label>
            </div>

            <div className="flex justify-center">
              <button onClick={handleClose} className="px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg text-xl hover:opacity-90 transition-all transform hover:scale-105">
                Let's Play! 🎲
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </Modal>
  );
}
