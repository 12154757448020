import React from "react";
import dice1 from "../../assets/img/dice/1.png";
import dice2 from "../../assets/img/dice/2.png";
import dice3 from "../../assets/img/dice/3.png";
import dice4 from "../../assets/img/dice/4.png";
import dice5 from "../../assets/img/dice/5.png";
import dice6 from "../../assets/img/dice/6.png";

const diceImages = {
  1: dice1,
  2: dice2,
  3: dice3,
  4: dice4,
  5: dice5,
  6: dice6,
};

export function PreviousDice({ value }) {
  return (
    <div className="group relative w-10 h-10 glass-card rounded-xl flex items-center justify-center flex-shrink-0 p-1.5 hover:scale-110 transition-all duration-200 scroll-item hover:bg-white/10">
      <img src={diceImages[value]} alt={`Dice ${value}`} className="w-full h-full object-contain transition-transform duration-200 group-hover:scale-110" />
      <div className="absolute inset-0 rounded-xl ring-2 ring-white/10 group-hover:ring-white/30 transition-all duration-200"></div>
    </div>
  );
}
