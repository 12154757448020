import { motion, AnimatePresence } from "framer-motion";

export function TabContent({ children, isActive }) {
  return (
    <AnimatePresence mode="wait">
      {isActive && (
        <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -10 }} transition={{ duration: 0.15 }} className="overflow-y-auto scroll-smooth pb-16">
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
