import React from "react";
import { Modal } from "./Modal";
import { motion } from "framer-motion";

// Import gambar goat
import overWinsGoat from "../../assets/img/bg/overwins.png";
import underWinsGoat from "../../assets/img/bg/underwins.png";
import burntGoat from "../../assets/img/bg/burntgoat.png";

export function RoundStatusModal({ isOpen, previousRoll, currentRoll, result, burnAmount, sentAmount }) {
  // Determine result based on dice comparison if result is undefined
  const determineResult = () => {
    if (currentRoll === previousRoll) return "tie";
    if (currentRoll > previousRoll) return "over";
    return "under";
  };

  const actualResult = result || determineResult();

  const getResultDetails = () => {
    switch (actualResult) {
      case "over":
        return {
          emoji: "🚀",
          color: "text-blue-500",
          message: "OVER WINS!",
          arrow: "↑",
          description: "Higher roll wins the round!",
          goatImage: overWinsGoat,
        };
      case "under":
        return {
          emoji: "⚡",
          color: "text-purple-500",
          message: "UNDER WINS!",
          arrow: "↓",
          description: "Lower roll wins the round!",
          goatImage: underWinsGoat,
        };
      case "tie":
        return {
          emoji: "🎯",
          color: "text-gray-500",
          message: "TIE - GOAT WINS!",
          arrow: "=",
          description: "Same number, Goat eats it all!",
          goatImage: burntGoat,
        };
      default:
        return {
          emoji: "🎯",
          color: "text-gray-500",
          message: "TIE - GOAT WINS!",
          arrow: "=",
          description: "Same number, Goat eats it all!",
          goatImage: burntGoat,
        };
    }
  };

  const details = getResultDetails();
  const animationConfig = {
    scale: { values: [1, 1.1, 1] },
    duration: 2,
    repeat: Infinity,
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="text-center font-vt323">
        <motion.div initial={{ scale: 0.5, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: 0.5 }} className="mb-4">
          {/* Header with Goat Image */}
          <div className="mb-6 relative">
            <motion.img src={details.goatImage} alt="Goat Result" className="w-32 h-32 mx-auto mb-4 object-contain" initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.2, duration: 0.5 }} />
            <motion.div
              animate={{ scale: animationConfig.scale.values }}
              transition={{
                duration: animationConfig.duration / 2,
                repeat: animationConfig.repeat,
              }}
            >
              <p className={`text-3xl font-bold ${details.color}`}>
                {details.emoji} {details.message} {details.emoji}
              </p>
            </motion.div>
            <p className="text-gray-500 text-lg">{details.description}</p>
          </div>

          {/* Dice Comparison */}
          <div className="flex justify-center items-center gap-8 bg-gray-50 p-6 rounded-xl">
            {/* Previous Roll */}
            <div className="relative">
              <p className="text-base text-gray-500 mb-2">Previous Roll</p>
              <div className="relative">
                <p className="text-5xl font-bold">{previousRoll}</p>
              </div>
            </div>

            {/* Arrow Indicator */}
            <div className="flex flex-col items-center">
              <div className={`text-4xl font-bold ${details.color}`}>{details.arrow}</div>
              <motion.div
                animate={{ x: [-10, 10, -10] }}
                transition={{
                  duration: animationConfig.duration,
                  repeat: animationConfig.repeat,
                }}
                className={`text-2xl ${details.color}`}
              >
                {actualResult === "over" ? "Higher" : actualResult === "under" ? "Lower" : "Equal"}
              </motion.div>
            </div>

            {/* Current Roll */}
            <div className="relative">
              <p className="text-base text-gray-500 mb-2">New Roll</p>
              <div className="relative">
                <p className="text-5xl font-bold">{currentRoll}</p>
              </div>
            </div>
          </div>

          {/* Token Info Section */}
          {(burnAmount > 0 || sentAmount > 0) && (
            <div className="mt-6 space-y-3">
              {/* Burn Info */}
              {burnAmount > 0 && (
                <div className="p-4 bg-gradient-to-r from-red-500/10 to-transparent rounded-lg">
                  <div className="flex items-center justify-center gap-2 text-lg">
                    <span className="text-red-500">🔥</span>
                    <span className="font-medium">{burnAmount / 1e6}</span>
                    <span className="text-gray-600">$GOATAGI tokens burned this round</span>
                  </div>
                </div>
              )}

              {/* Sent Info
              {sentAmount > 0 && (
                <div className="p-4 bg-gradient-to-r from-green-500/10 to-transparent rounded-lg">
                  <div className="flex items-center justify-center gap-2 text-lg">
                    <span className="text-green-500">🎁</span>
                    <span className="font-medium">{sentAmount / 1e6}</span>
                    <span className="text-gray-600">$GOATAGI tokens sent to winners</span>
                  </div>
                </div>
              )} */}
            </div>
          )}
        </motion.div>
      </div>
    </Modal>
  );
}
