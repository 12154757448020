import { Modal } from "./Modal";
import { CopyableAddress } from "./CopyableAddress";

export function GameRules({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xlarge">
      <div className="font-vt323">
        <h2 className="text-4xl md:text-5xl font-bold mb-8 text-center bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text">🎲 How to Play 🎲</h2>

        <div className="space-y-8 text-xl md:text-2xl">
          <section className="glass-card p-6 rounded-xl">
            <h3 className="text-3xl md:text-4xl font-bold mb-4 text-blue-600">🎮 Game Rules</h3>
            <ul className="list-none space-y-4 text-gray-700">
              <li className="flex items-center gap-3">
                <span className="text-blue-500">•</span>
                Place your bet on OVER or UNDER
              </li>
              <li className="flex items-center gap-3">
                <span className="text-blue-500">•</span>
                If current dice is 6, you can't bet OVER
              </li>
              <li className="flex items-center gap-3">
                <span className="text-blue-500">•</span>
                If current dice is 1, you can't bet UNDER
              </li>
              <li className="flex items-center gap-3">
                <span className="text-red-500">•</span>
                Betting locks 10 seconds before roll
              </li>
              <li className="flex items-center gap-3">
                <span className="text-green-500">💰</span>
                Winners receive 100% rewards in SOL
              </li>
              <li className="flex items-center gap-3">
                <span className="text-red-500">🔥</span>
                2.5% of every bet buys & burns $GOATAGI
              </li>
              <li className="flex items-center gap-3 text-md">
                <span className="text-gray-500">📝</span>
                Contract: <CopyableAddress address="33a14qXWo1MB7uXtTh5ifyvox7FdGRPQbsws41gfpump" truncate={true} isMobile={true} />
              </li>
            </ul>
          </section>

          <section className="glass-card p-6 rounded-xl">
            <h3 className="text-3xl md:text-4xl font-bold mb-4 text-purple-600">🏆 Winning Conditions</h3>
            <ul className="list-none space-y-4 text-gray-700">
              <li className="flex items-center gap-3">
                <span className="text-blue-500">↑</span>
                Bet OVER: You win if next roll is higher than current roll
              </li>
              <li className="flex items-center gap-3">
                <span className="text-purple-500">↓</span>
                Bet UNDER: You win if next roll is lower than current roll
              </li>
              <li className="flex items-center gap-3">
                <span className="text-gray-500">=</span>
                If next roll equals current roll, Goat wins
              </li>
            </ul>
          </section>

          <section className="glass-card p-6 rounded-xl">
            <h3 className="text-3xl md:text-4xl font-bold mb-4 text-green-600">💰 Payout Structure</h3>
            <ul className="list-none space-y-4 text-gray-700">
              <li className="flex flex-col gap-2">
                <span className="text-blue-500 font-bold">OVER BETS:</span>
                <div className="ml-8 space-y-2 text-lg">
                  <p>
                    Current Roll 1: <span className="text-blue-600">1.1x</span>
                  </p>
                  <p>
                    Current Roll 2: <span className="text-blue-600">1.3x</span>
                  </p>
                  <p>
                    Current Roll 3: <span className="text-blue-600">1.8x</span>
                  </p>
                  <p>
                    Current Roll 4: <span className="text-blue-600">2.7x</span>
                  </p>
                  <p>
                    Current Roll 5: <span className="text-blue-600">5.4x</span>
                  </p>
                </div>
              </li>
              <li className="flex flex-col gap-2">
                <span className="text-purple-500 font-bold">UNDER BETS:</span>
                <div className="ml-8 space-y-2 text-lg">
                  <p>
                    Current Roll 2: <span className="text-purple-600">5.4x</span>
                  </p>
                  <p>
                    Current Roll 3: <span className="text-purple-600">2.7x</span>
                  </p>
                  <p>
                    Current Roll 4: <span className="text-purple-600">1.8x</span>
                  </p>
                  <p>
                    Current Roll 5: <span className="text-purple-600">1.3x</span>
                  </p>
                  <p>
                    Current Roll 6: <span className="text-purple-600">1.1x</span>
                  </p>
                </div>
              </li>
            </ul>
          </section>

          <div className="text-center mt-10">
            <button onClick={onClose} className="px-10 py-4 text-2xl bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg hover:opacity-90 transition-all transform hover:scale-105">
              Let's Play! 🎲
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
