export const formatNumber = (number) => {
  // Memastikan number valid dan mengkonversi ke string dengan 3 desimal
  const formatted = Number(number).toFixed(3);

  // Memisahkan bagian integer dan desimal
  const [intPart, decPart] = formatted.split(".");

  // Format bagian integer dengan pemisah ribuan
  const formattedInt = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Gabungkan kembali dengan bagian desimal
  return `${formattedInt}.${decPart}`;
};
