export function Button({ children, className = "", variant = "primary", ...props }) {
  const baseStyles = "h-10 px-3 rounded-lg font-medium transition-all duration-200 flex items-center justify-center transform hover:scale-105 active:scale-95";
  const variants = {
    primary: "bg-black text-white hover:bg-gray-900 shadow-lg",
    secondary: "bg-gray-100 text-black hover:bg-gray-200 border border-gray-200 shadow-md",
    over: "bg-blue-50 text-blue-800 hover:bg-blue-100 border border-blue-200 shadow-md",
    under: "bg-purple-50 text-purple-800 hover:bg-purple-100 border border-purple-200 shadow-md",
    disabled: "bg-gray-50 text-gray-400 border border-gray-200 cursor-not-allowed opacity-50",
  };

  return (
    <button className={`${baseStyles} ${variants[variant]} ${className}`} {...props}>
      <span className="text-xs sm:text-sm whitespace-nowrap">{children}</span>
    </button>
  );
}
