import React, { useEffect, useRef, useState } from "react";
import goatmain from "../../assets/img/bg/goatmain.png";

export function Dice3D({ value = 1, isRolling = false }) {
  const diceRef = useRef(null);
  const [currentSide, setCurrentSide] = useState(value);

  useEffect(() => {
    if (isRolling) {
      diceRef.current.classList.remove("reRoll");

      let rollCount = 0;
      const maxRolls = 400;
      const rollInterval = setInterval(() => {
        rollCount++;
        const randomSide = Math.floor(Math.random() * 6) + 1;
        setCurrentSide(randomSide);

        if (rollCount >= maxRolls) {
          clearInterval(rollInterval);
          setCurrentSide(value);
          void diceRef.current.offsetWidth;
          diceRef.current.classList.add("reRoll");
        }
      }, 100);

      return () => clearInterval(rollInterval);
    } else {
      setCurrentSide(value);
    }
  }, [isRolling, value]);

  return (
    <div className="relative w-32 h-32 mx-auto">
      {/* Background Goats dengan animasi */}
      <div className="absolute -inset-32 md:-inset-32">
        <div className={`absolute inset-0 ${isRolling ? "animate-spin-slow" : ""}`}>
          <img src={goatmain} alt="Goats" className="w-full h-full object-contain" />
        </div>
      </div>

      {/* 3D Dice */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="dice-3d-wrapper">
          <div ref={diceRef} className="dice-3d" data-side={currentSide}>
            <div className="sides side-1">
              <span className="dot dot-1"></span>
            </div>
            <div className="sides side-2">
              <span className="dot dot-1"></span>
              <span className="dot dot-2"></span>
            </div>
            <div className="sides side-3">
              <span className="dot dot-1"></span>
              <span className="dot dot-2"></span>
              <span className="dot dot-3"></span>
            </div>
            <div className="sides side-4">
              <span className="dot dot-1"></span>
              <span className="dot dot-2"></span>
              <span className="dot dot-3"></span>
              <span className="dot dot-4"></span>
            </div>
            <div className="sides side-5">
              <span className="dot dot-1"></span>
              <span className="dot dot-2"></span>
              <span className="dot dot-3"></span>
              <span className="dot dot-4"></span>
              <span className="dot dot-5"></span>
            </div>
            <div className="sides side-6">
              <span className="dot dot-1"></span>
              <span className="dot dot-2"></span>
              <span className="dot dot-3"></span>
              <span className="dot dot-4"></span>
              <span className="dot dot-5"></span>
              <span className="dot dot-6"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
