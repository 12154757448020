import { motion } from "framer-motion";
import { formatNumber } from "../../utils/formatNumber";
import { CopyableAddress } from "../ui/CopyableAddress";

export function InfoTab({ burnAmount }) {
  return (
    <div className="space-y-4 p-3">
      {/* Token Info Card */}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="glass-card p-4 rounded-xl"
      >
        <div className="text-center mb-6">
          <h3 className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text">
            $GOATAGI Token
          </h3>
          <p className="text-gray-600 text-sm">
            The First Deflationary Gambling Token
          </p>
        </div>

        <motion.div
          whileHover={{ scale: 1.02 }}
          className="glass-card px-6 py-4 rounded-xl bg-gradient-to-r from-red-50 to-transparent mb-4"
        >
          <div className="flex items-center gap-3">
            <span className="text-2xl">🔥</span>
            <div>
              <p className="font-medium text-gray-800">Whitepaper</p>
              <p className="text-sm text-gray-600">
                <a
                  href="/GOAT_ARENA_A-Memecoin-Prediction-Market.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-gray-900 transition-colors"
                  aria-label="Twitter"
                >
                  Click here to download
                </a>
              </p>
            </div>
          </div>
        </motion.div>

        {/* Burn Mechanism */}
        <motion.div
          whileHover={{ scale: 1.02 }}
          className="glass-card px-6 py-4 rounded-xl bg-gradient-to-r from-red-50 to-transparent mb-4"
        >
          <div className="flex items-center gap-3">
            <span className="text-2xl">🔥</span>
            <div>
              <p className="font-medium text-gray-800">Burn Mechanism</p>
              <p className="text-sm text-gray-600">
                2.5% of every bet <span className="text-green-600">buys</span> &{" "}
                <span className="text-red-600">burns</span> $GOATAGI
              </p>
            </div>
          </div>
        </motion.div>

        {/* Total Burnt Display */}
        <motion.div
          whileHover={{ scale: 1.02 }}
          className="glass-card p-4 rounded-xl bg-gradient-to-r from-orange-50 to-transparent mb-4"
        >
          <div className="text-center">
            <p className="text-sm text-gray-600 mb-1">Total $GOATAGI Burnt</p>
            <div className="flex items-center justify-center gap-2">
              <span className="text-3xl font-bold text-red-500">
                {formatNumber(burnAmount)}
              </span>
              <motion.span
                animate={{ rotate: [0, -20, 20, -20, 20, 0] }}
                transition={{ duration: 2, repeat: Infinity, repeatDelay: 3 }}
                className="text-2xl"
              >
                🔥
              </motion.span>
            </div>
          </div>
        </motion.div>

        {/* Contract Info */}
        <motion.div
          whileHover={{ scale: 1.02 }}
          className="glass-card p-4 rounded-xl bg-gradient-to-r from-blue-50 to-transparent"
        >
          <div className="space-y-2">
            <div className="flex items-center gap-2 mb-2">
              <span className="text-xl">📝</span>
              <p className="font-medium text-gray-800">Smart Contract</p>
            </div>
            <div className="flex items-center justify-center">
              <CopyableAddress
                address="33a14qXWo1MB7uXtTh5ifyvox7FdGRPQbsws41gfpump"
                truncate={true}
              />
            </div>
          </div>
        </motion.div>
      </motion.div>

      {/* Additional Info */}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="text-center text-sm text-gray-500"
      >
        <p>More gambol = more burn = 🚀 Highor prize baaaa 🐐🔥</p>
      </motion.div>
    </div>
  );
}
